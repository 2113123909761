import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as MediaIcon } from '../../icon/media.svg'

import DotMenu from '../Elements/DotMenu'
import FrameTitle from '../Frame/FrameTitle'
import MediaPageContent from './MediaPageContent'
import MarkdownText from '../Elements/MarkdownText'

import model from '../../model'

export default function MemberPageDisplay({ id, pathSuffix, rec }) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))
    const isOwner = model.helper('media.isOwner', { memberAuthed, rec })

    const subject = model.helper('media.getSubjectFromId', {id})

    const navigate = useNavigate()

    if (!rec) {
        return <div />
    }

    /*
    const imageStyle = {
        backgroundImage: `url(${model.helper('member.getImageUrl', { member: rec })})`
    }
    */

    let actions = []
    if (isOwner || isAdmin) actions.push({
        label: 'edit',
        onClick: () => {
            navigate(`${pathSuffix}/edit`)
        },
    })

    if (isOwner || isAdmin) actions.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });
    if (isOwner || isAdmin) actions.push({
        label: 'remove',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'subjectRemove',
                subject,
            })
        }
    });

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <MediaIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    media
                </div>
                <div className="">
                    <DotMenu direction="right" options={actions} theme="light" />
                </div>
            </FrameTitle>
            <div className="w-full bg-slate-950">
                <MediaPageContent rec={rec} subject={subject}/>
                {rec.caption && (
                        <div className="flex flex-col lg:flex-row items-center px-6 pb-6">
                            <div className="text-base xl:text-lg font-regular text-left text-slate-800">
                                <MarkdownText className="text-white">
                                    {rec.caption}
                                </MarkdownText>
                            </div>
                        </div>
                )}
                
            </div>


        </React.Fragment>
    )
}