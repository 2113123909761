import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ProjectIcon } from '../../icon/project.svg'
import { ReactComponent as LogoMark } from '../../icon/lotus-counter-logo.svg'

import Button from '../Elements/Button'
import Container from '../Elements/Container'
import DotMenu from '../Elements/DotMenu'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'

import MarkdownText from '../Elements/MarkdownText'
import SubjectGrixis from '../SubjectGrixis'

import model from '../../model'

export default function LotucCounterPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const id = '6716b1fe82dc9e99368d4251'

    const heroRef = useRef(null)
    const logoRef = useRef(null)

    const navigate = useNavigate()
    const rec = useSelector(state => model.selector('project.recById', { state, id }))

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const isOwner = model.helper('project.isOwner', { rec })

    const pathSuffix = `/home`

    const subject = {
        model: 'project',
        id,
    }

    const winHeight = window.innerHeight

    const onScroll = useCallback((e) => {
        const position = window.scrollY
        let size = (winHeight - (position / 2)) / winHeight
        size = size > 0 ? size : 0
        if (heroRef.current) {
            heroRef.current.style.top = (position / 2) + 'px'
            heroRef.current.style.opacity = size
        }
        if (logoRef.current) {
            logoRef.current.style.top = (position / 4) + 'px'

            logoRef.current.style.transform = `scale(${size},${size})`
            logoRef.current.style.opacity = size
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [onScroll])


    useEffect(() => {
        if (!id) return
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('project.getById', { id })
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id])

    let actions = []
    if (isOwner || isAdmin){
        actions.push({
            label: 'edit',
            onClick: () => {
                navigate(`/project/${id}/edit`)
            }
        })
    }

    if (isOwner || isAdmin) actions.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });

    if (isOwner || isAdmin) actions.push({
        label: 'display media',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'displayMediaSelect',
                subject
            })
        }
    });

    const handleGoKickstarter = async ()=>{
        
    }

    const handleSubscribe = async ()=>{
        await model.action('app.setModal', {
            modal: 'lotusCounterSubscribe'
        })
    }

    return (
        <div className="w-full">
            <div className="bg-cover flex flex-row items-center justify-center bg-center h-[100vh] pb-[10vh] relative" ref={heroRef} style={{ backgroundImage: 'url(/lotus-banner.png)' }}>
                <LogoMark className="w-[50vh] text-white relative" ref={logoRef} />
            </div>

            {!rec ? (
                <Frame className="relative top-[-20vh]">
                    <FrameTitle>
                        <div className="flex flex-row items-center">
                            <ProjectIcon className="w-5 h-5 text-lime-400" />
                        </div>
                        <div className="text-white font-bold text-lg">
                            project
                        </div>
                    </FrameTitle>
                    <div className="w-full bg-white relative">
                        <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                            <div className="animate-pulse h-6 bg-slate-300 rounded-full dark:bg-slate-600 w-[220px]" />
                            <div className="flex flex-col gap-2">
                                <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[100%]" />
                                <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[60%]" />
                            </div>
                        </Container>
                    </div>
                    <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
                </Frame>
            ) : (
                <Frame className="relative top-[-20vh]">
                    <FrameTitle>
                        <div className="flex flex-row items-center">
                            <ProjectIcon className="w-5 h-5 text-lime-400" />
                        </div>
                        <div className="text-white font-bold text-lg">
                            project
                        </div>
                        <DotMenu direction="right" options={actions} theme="light" />
                    </FrameTitle>

                    <div className="w-full bg-white relative">
                        <Container className="flex flex-col gap-4 py-6 md:py-10 lg:py-12 xl:py-14">
                            <h2 className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.5rem] leading-tight font-bold text-slate-800 mb-0">{rec.title}</h2>
                            <MarkdownText className="font-regular text-base md:text-lg lg:text-xl leading-normal text-slate-800">{rec.description}</MarkdownText>
                            <div className="flex flex-row items-center gap-4 mt-4">
                                <Button className="hidden" onClick={handleGoKickstarter} size="lg" theme="primary">Kickstarter</Button>
                                <Button onClick={handleSubscribe} size="lg">Subscribe for Updates</Button>
                            </div>
                        </Container>
                    </div>
                    <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
                </Frame>
            )}

        </div>
    )
}