import React from 'react'

import { useSelector } from 'react-redux'
import model from '../../model'

import { ReactComponent as MediaIcon } from '../../icon/media.svg'

import Grix from '../Grixis/Grix'

export default function MediaGrix({ deselected, handleClick, handleEdit, handleSelect, selected, setView, size = [1,1], subject, view }) {
    const rec = useSelector(state => model.selector('subject.rec', { state, subject })) || {}

    const url = rec.cloudinary && rec.cloudinary.url ? rec.cloudinary.url : ''

    const handleMainClick = ()=>{
        if (handleSelect) handleSelect({subject})
        if (handleClick) handleClick({subject})
    }

    const renderContent = () => {
        if (!rec) return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center">

            </div>
        )

        if (rec.type === 'video') {
            return (
                <video width="100%" height="100%" src={url} controls autoplay>
                    Your browser does not support the video tag.
                </video>

            )
        }
        if (rec.type === 'image') {
            return (
                <div className="absolute group top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-center bg-no-repeat bg-cover"
                    style={{ backgroundImage: `url(${url})` }} >
                </div>

            )
        }

        return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center">

            </div>
        )
    }

    return (
        <Grix size={size}>
            <div className={`absolute cursor-pointer top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-slate-800 ${deselected ? 'opacity-50 hover:opacity-80' : 'opacity-80 hover:opacity-100'} transition`}
                onClick={handleMainClick}>
                {renderContent()}
                <div className="absolute top-1 left-1 bg-slate-900 gap-2.5 p-1.5 rounded flex flex-row items-center group w-6 h-6 hover:w-[80px] transition-[width] overflow-hidden">
                    <MediaIcon className="w-3 h-3 text-lime-400 shrink-0" />
                    <div className="text-xs text-white font-semibold">
                        media
                    </div>
                </div>
            </div>
        </Grix>
    )
}