import React from 'react'

import { useSelector } from 'react-redux'
import model from '../../model'

import { ReactComponent as MemberIcon } from '../../icon/member.svg'

import Grix from '../Grixis/Grix'

export default function MemberGrix({ deselected, handleClick, handleEdit, selected, setView, subject, view }) {
    const rec = useSelector(state => model.selector('subject.rec', { state, subject })) || {}

    const displayMediaId = rec && rec.displayMediaId ? rec.displayMediaId : null
    const displayMedia = useSelector(state => model.selector('media.recById', { state, id: displayMediaId }))
    const displayMediaUrl = displayMedia && displayMedia.cloudinary && displayMedia.cloudinary.url ? displayMedia.cloudinary.url : ''

    const memberImage = model.helper('member.getImageUrl', { member: rec })

    const mediaUrl = displayMediaUrl? displayMediaUrl : memberImage

    let size = [1, 1]

    const handleMainClick = () => {
        if (handleClick) handleClick({ subject })
    }

    const title = model.helper('member.getTitle', { rec })

    return (
        <Grix size={size}>
            <div className={`absolute cursor-pointer top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-slate-800 ${deselected ? 'opacity-50 hover:opacity-80' : 'opacity-80 hover:opacity-100'} transition`}
                onClick={handleMainClick}>

                <div className="absolute group top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-center bg-no-repeat bg-cover"
                    style={{ backgroundImage: `url(${mediaUrl})` }} >
                </div>

                <div className="absolute top-1 left-1 bg-slate-900 gap-2.5 p-1.5 rounded flex flex-row items-center group w-6 h-6 hover:w-[90px] transition-[width] overflow-hidden">
                    <MemberIcon className="w-3 h-3 text-lime-400 shrink-0" />
                    <div className="text-xs text-white font-semibold">
                        member
                    </div>
                </div>

                <div className="bg-slate-900/90 flex flex-col items-center justify-center h-8 absolute bottom-0 left-0 right-0">
                    <div className="text-sm font-semibold text-white">
                        {title}
                    </div>
                </div>
                
            </div>
        </Grix>
    )
}